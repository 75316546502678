import React, { useContext } from 'react';
import styled from 'styled-components';

import { jetBlack } from '@shipae/components-sandbox/component/colors';
import Breadcrumbs from '@shipae/components-sandbox/component/breadcrumbs';
import BreadcrumbsTheme
  from '@shipae/components-sandbox/component/breadcrumbs/theme';
import { ReactComponent as ArrowRightComponent }
  from '@shipae/components-sandbox/component/static-icons/arrow-right.svg';

import { media, fonts } from 'views/theme';
import { Faq } from 'infrastructure/gatsby/types/faq';
import { calculateBreadcrumbs } from 'views/utils/calculate-breadcrumbs';
import { PageContext } from 'views/templates/context';
import SmartLink from 'components/smart-link';
import { useTranslation } from 'src/views/misc/localization';
import { ROUTES } from '../../constants/ROUTES';

const Section = styled.section`
  width: var(--container-width);
  margin: 0 auto;
  padding: 11.8rem 0 3rem 0;
`;

const Title = styled.h1`
  margin-bottom: 12rem;
  ${ fonts.displaym200 }
`;

const IconList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface ILink {
  external?: boolean;
}

const ArrowRight = styled(ArrowRightComponent)`
  display: none;
  width: 2.4rem;
  margin-left: .8rem;

  html[dir='rtl'] & {
    transform: rotate(180deg);
  }
`;

const linkTheme = {
  Main: styled.a<ILink>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 16rem;
    margin: 0 8.8rem 8.8rem 0;
    text-decoration: none;

    ${ media.max(1600) } {
      margin: 0 4.8rem 4.8rem 0;
    }

    ${ media.max(1300) } {
      margin: 0 2.8rem 2.8rem 0;
    }

    ${ media.max(1100) } {
      margin: 0 1rem 2.8rem 0;
    }

    ${ media.md } {
      margin: 0 0 2.8rem 0;
    }

    ${ (pr) => (pr.external ? `
       ${ ArrowRight } {
         display: block;
       }
    ` : null) }
  `,
};

const Icon = styled.img`
  width: 4.8rem;
  margin-bottom: 1.6rem;
`;

const Label = styled.h2`
  display: flex;
  text-transform: uppercase;
  color: ${ jetBlack() };
  ${ fonts.bodys300 }
`;

const LabelText = styled.span`
`;

const breadcrumbsTheme = {
  ...BreadcrumbsTheme,
  Main: styled(BreadcrumbsTheme.Main)`
    margin-bottom: 1.2rem;
  `,
};

const FaqSection: React.FC<Faq> = ({
  sectionId,
  title,
  showBreadcrumbs,
  icons,
}) => {
  const { locale, t } = useTranslation();
  const pageContext = useContext(PageContext);

  return (
    <Section id={sectionId}>
      {showBreadcrumbs
        ? (
          <Breadcrumbs
            home={{
              label: t('home'),
              url: ROUTES[locale].HOME,
            }}
            links={calculateBreadcrumbs({
              parentTitle: pageContext.pageSettings.parentPageTitle,
              parentPath: pageContext.pageSettings.parentPagePath,
              title: pageContext.title,
            })}
            theme={breadcrumbsTheme}
          />
        ) : null}
      <Title>{title}</Title>
      {icons && icons.length > 0
        ? (
          <IconList>
            {icons.map((icon) => (
              <SmartLink
                key={icon.id}
                href={icon.url}
                theme={linkTheme}
              >
                <Icon
                  src={icon.icon.localFile?.publicURL}
                  alt={icon.icon.alternativeText || ''}
                  loading="lazy"
                />
                <Label>
                  <LabelText>{icon.label}</LabelText>
                  <ArrowRight />
                </Label>
              </SmartLink>
            ))}
          </IconList>
        ) : null}
    </Section>
  );
};

export default FaqSection;
